























































































































































































import { Component, Mixins } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import Axios from "axios";
import { formatPrice, capitalizeAllWords } from "@/utils/string";
import { DEFAULT_MONEY_RANGE_VALUES, CURRENCY } from "@/constants";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {},
})
export default class SearchProfileVIew extends Mixins(RulesMixin) {
  wasSubmitted = false;
  isFormValid = false;
  isFormSubmitting = false;
  formData: any = {
    firstName: "",
    lastName: "",
    // street: "",
    // zipCode: "",
    // city: "",
    email: "",
    phone: "",
    priceRange: [1, 16],
    message: "",
    // companyName: "",
    regionId: null,
    branchIds: [],
  };
  steps: number[] = DEFAULT_MONEY_RANGE_VALUES;
  regions: ReferenceItem[] = [];
  industries: ReferenceItem[] = [
    {
      name: "Dienstleistung",
      id: 5,
    },
    {
      name: "Detailhandel",
      id: 8,
    },
    {
      name: "Handwerk / Baugewerbe",
      id: 13,
    },
    {
      name: "Grosshandel",
      id: 11,
    },
    {
      name: "E-Commerce",
      id: 6,
    },
    {
      name: "IT/Software",
      id: 7,
    },
    {
      name: "Gesundheit/Soziales",
      id: 18,
    },
    {
      name: "Industrie / Produktion",
      id: 15,
    },
  ];

  capitalizeAllWords = capitalizeAllWords;

  get rangePrice(): string {
    if (this.formData.priceRange) {
      return `${formatPrice(
        this.steps[this.formData.priceRange[0]],
        CURRENCY
      )} - ${formatPrice(this.steps[this.formData.priceRange[1]], CURRENCY)}`;
    }
    return "";
  }

  async loadSelections(): Promise<void> {
    const regions = await Axios.get("/api/v2/website/regions");
    this.regions = regions.data;
  }

  async submitForm(): Promise<void> {
    try {
      this.isFormSubmitting = true;
      await Axios.post("/api/v2/website/searchProfile", {
        ...this.formData,
        branchIds: this.formData.branchIds.toString(),
        priceFrom: this.steps[this.formData.priceRange[0]],
        priceTo: this.steps[this.formData.priceRange[1]],
      });
      this.wasSubmitted = true;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isFormSubmitting = false;
    }
  }

  created() {
    this.loadSelections();
  }
}
